import { createContext } from "react";
import { AuthStateProps } from "@kone/ui-library";

import { MenuItem } from "../constants/menus";
import { API_TYPE, REQUEST_TYPE } from "../misc/fetch";
import { Application, NetworkData } from "../schemas";

export interface ContextState {
  applications: Application[];
  authState: AuthStateProps;
  fetch: (
    url: string,
    api?: API_TYPE,
    requestType?: REQUEST_TYPE,
    params?: object
  ) => Promise<any>;
  jwtToken: string;
  ongoingNetworks: NetworkData[];
  selectedMenu?: MenuItem;
  unstartedNetworks: NetworkData[];
  updateApplications: () => void;
  updateAuthState: (authState: AuthStateProps) => void;
  updateJwtToken: (token: string) => void;
  updateSelectedMenu: (menu?: MenuItem) => void;
  setOngoingNetworks: (networks: NetworkData[]) => void;
  setUnstartedNetworks: (networks: NetworkData[]) => void;
}

export default createContext({} as ContextState);
