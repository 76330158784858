import React, { lazy, PropsWithChildren, Suspense, useContext, useEffect } from "react";
import { CssBaseline, Typography } from "@material-ui/core";
import { createStyles, makeStyles, Theme, ThemeProvider } from "@material-ui/core/styles";
import { HomeOutlined } from "@material-ui/icons";
import {
  AuthenticatorProps,
  theme,
  WithAuthenticator,
  WithAuthenticatorProps,
} from "@kone/ui-library";

import Context from "../../context";
import Loading from "../../components/Loading";
import HeaderBar from "../../components/HeaderBar";
import logo from "../../logo_inverted.svg";
import backgroundImage from "./background.jpg";

const Applications = lazy(() => import("../Applications"));
const UI_URL = process.env.UI_URL || "";
const useStyles = makeStyles((t: Theme) =>
  createStyles({
    root: {
      display: "flex",
    },
    // necessary for content to be below app bar
    toolbar: t.mixins.toolbar,
    content: {
      flexGrow: 1,
    },
    toBeStartedIcon: {
      display: "flex",
    },
    textWithBackground: {
      padding: theme.spacing(3),
      width: "100%",
      color: theme.palette.primary.contrastText,
      textShadow: `1px 1px ${theme.palette.text.secondary}`,
      backgroundSize: "cover",
      backgroundRepeat: "no-repeat",
      backgroundPosition: "100% 16%",
      backgroundImage: `url(${backgroundImage})`,
    },
  })
);

const App = (props: PropsWithChildren<WithAuthenticatorProps>): JSX.Element => {
  const {
    authState: { isAuthenticated, user },
    updateAuthState,
    updateJwtToken,
  } = useContext(Context);

  useEffect(() => {
    const updateLoginData = async (): Promise<void> => {
      try {
        const { accessToken } = await props.acquireToken(true);
        updateJwtToken(accessToken);
        await props.updateGraphData(accessToken);
        const authState = await props.getAuthState();
        updateAuthState(authState);
      } catch (error) {
        console.error("updateLoginData", error);
      }
    };
    if (!isAuthenticated) updateLoginData();
  }, [isAuthenticated]); // eslint-disable-line react-hooks/exhaustive-deps

  const classes = useStyles(theme);

  const renderThemedComponent = () => (
    <ThemeProvider theme={theme}>
      <div className={classes.root}>
        <CssBaseline />
        <HeaderBar
          handleSidebarToggle={() => undefined}
          isAuthenticated={isAuthenticated}
          onSignOut={props.onSignOut}
          sidemenuWidth={0}
          title="Application launcher"
          titleIcon={HomeOutlined}
          userMenuTitle={user?.userName}
          logo={logo}
        />
        <main className={classes.content}>
          <div className={classes.toolbar} />
          <Typography
            component="div"
            className={classes.textWithBackground}
            paragraph
            gutterBottom
          >
            <h3>Welcome to KONE Installation Execution System (IES)</h3>
            <h4>
              All Kone applications in one place. Discover and start using Kone
              applications on demand. Get started by launching one of the Kone
              applications listed bellow.
            </h4>
          </Typography>

          <Suspense fallback={<Loading />}>
            <Applications />
          </Suspense>
        </main>
      </div>
    </ThemeProvider>
  );

  return isAuthenticated ? renderThemedComponent() : <Loading invisible />;
};

export { App as PureComponent };
export default WithAuthenticator(App, {
  clientId: "c49d3833-ee3c-4bfa-8fa8-d7566b7ec0fd",
  authority: "https://login.microsoftonline.com/2bb82c64-2eb1-43f7-8862-fdc1d2333b50",
  redirectUri: UI_URL,
  validateAuthority: true,
  postLogoutRedirectUri: UI_URL,
  navigateToLoginRequestUrl: false,
} as AuthenticatorProps);
