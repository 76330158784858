/* eslint-disable @typescript-eslint/no-explicit-any */
import axios from "axios";

export enum API_TYPE {
  PORTAL,
  APPLICATION,
}

export enum REQUEST_TYPE {
  PATCH,
  GET,
}

const APIGW_URL = process.env.APIGW_URL || "";
const IES_APP_API_URL = process.env.REACT_APP_IES_APP_API_URL || "";

export const get = async (
  url: string,
  token: string,
  api: API_TYPE = API_TYPE.PORTAL,
  params?: object
): Promise<any> => {
  try {
    const baseURL = api === API_TYPE.PORTAL ? APIGW_URL : IES_APP_API_URL;

    const { data } = await axios.get(`${baseURL}${url}`, {
      params,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    return data;
  } catch (error) {
    console.error(error);

    return null;
  }
};

export const patch = async (
  url: string,
  token: string,
  api: API_TYPE = API_TYPE.PORTAL,
  params?: object
): Promise<any> => {
  const baseURL = api === API_TYPE.PORTAL ? APIGW_URL : IES_APP_API_URL;

  const { data } = await axios.patch(`${baseURL}${url}`, params, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });

  return data;
};
