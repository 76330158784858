import React, { useState } from "react";
import {
  AccountCircle as AccountCircleIcon,
  KeyboardArrowDown as KeyboardArrowDownIcon,
} from "@material-ui/icons";
import { Button, Menu, MenuItem } from "@material-ui/core";

interface UserMenuProps {
  onSignOut: () => void;
  title?: string;
}
const UserMenu = (props: UserMenuProps): JSX.Element => {
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);

  const handleMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <Button
        id="usermenu-button"
        color="inherit"
        onClick={handleMenu}
        startIcon={<AccountCircleIcon />}
      >
        {props.title} <KeyboardArrowDownIcon />
      </Button>
      <Menu
        id="usermenu-menu"
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        keepMounted
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        <MenuItem id="usermenu-menu-signout-item" onClick={props.onSignOut}>
          Sign Out
        </MenuItem>
      </Menu>
    </>
  );
};

export default UserMenu;
