import React from "react";
import { render } from "react-dom";
import { WithAuthenticatorProps } from "@kone/ui-library";

import App from "./containers/App";
import GlobalState from "./context/globalState";

const props = {} as WithAuthenticatorProps;

render(
  <GlobalState>
    <App {...props} />
  </GlobalState>,
  document.getElementById("app")
);
