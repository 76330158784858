import React, { lazy } from "react";
import { SvgIconComponent, HomeOutlined } from "@material-ui/icons";

const Applications = lazy(() => import("../containers/Applications"));

export interface MenuItem {
  title: string;
  url: string;
  icon?: SvgIconComponent;
  component: React.LazyExoticComponent<any>;
}

const MENUITEMS: MenuItem[] = [
  {
    title: "Applications",
    url: "/applications",
    component: Applications,
    icon: HomeOutlined,
  },
];

export default MENUITEMS;
